<template>
  <div>
    <div v-bind="getRootProps()">
      <div v-for="file in files" :key="file.id">
        <img :src="fileUrl(file.id)" />
      </div>
      <input v-bind="getInputProps()" />
      <p class="cursor-pointer">
        <span v-if="isDragActive">Drop the files here</span>
        <span v-else>{{ prompt || "Select a file..." }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { getCurrentInstance } from "vue";
import { utils } from "common-frontend";

const { keysToCamel } = utils;

export default {
  name: "Dropzone",
  props: {
    files: Array,
    maxFiles: Number,
    accept: [String, Array],
    prompt: String,
  },
  setup(props, context) {
    const app = getCurrentInstance();
    const { $api } = app.appContext.config.globalProperties;
    const url = `${import.meta.env.VITE_API_BASE_URL}/file-upload/`; // Your url on the server side
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      for (let x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        formData.append("files[]", files[x]);
      }

      $api.axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          context.emit("uploaded", keysToCamel(response.data));
        })
        .catch((err) => {
          console.error(err); // eslint-disable-line
        });
    };

    function onDrop(acceptFiles) {
      saveFiles(acceptFiles);
    }

    const opts = {
      maxFiles: props.maxFiles,
      onDrop,
      accept: props.accept,
    };

    const { getRootProps, getInputProps, ...rest } = useDropzone(opts);

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  methods: {
    fileUrl(hashedId) {
      return `${import.meta.env.VITE_API_BASE_URL}/file/${hashedId}/content/`;
    },
  },
};
</script>
