import { createApp, nextTick } from "vue";

// styles

import "./assets/styles/tailwind.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueNextSelect from "vue-next-select";
import "./assets/styles/vue-next-select.css";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import calendar from "dayjs/plugin/calendar";
import Ably from "ably";
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import posthog from "posthog-js";
import NProgress from "nprogress";
import "./assets/styles/nprogress.css";

// mouting point for the whole app

import { store, utils } from "common-frontend";
import App from "./App.vue";

// router

import router from "./router";

const { apiPlugin, posthogPlugin, perplexicaPlugin } = utils;

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN !== "null") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [import.meta.env.VITE_ADMIN_BASE_URL, /^\//],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    tracesSampleRate: 0.1,
    logErrors: true,
    trackComponents: true,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const toastOptions = {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
};
app.use(Toast, toastOptions);
app.use(posthogPlugin);
app.use(perplexicaPlugin);

app.use(router);
app.use(store);
app.use(apiPlugin);
app.component("VSelect", vSelect);
app.component("VueSelect", VueNextSelect);

dayjs.extend(relativeTime);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

window.dayjs = dayjs;

const ably = new Ably.Realtime.Promise({
  key: "GmGsAA.7FvzJw:JerijPnXDZ47sOI7w7jIVcmiYlVGMWk5ckF-9QVZf_k",
  clientId: "Cory",
});

app.config.globalProperties.$ably = ably;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$toast = useToast();

NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });

store.watch(
  (state) => state.loader.loading,
  (newVal, oldVal) => {
    if (newVal === 0) return NProgress.done();
    if (oldVal === 0) return NProgress.start();
    return NProgress.set(1.8 / Math.max(oldVal, newVal));
  },
);

router.beforeEach((to, from) => {
  if (to !== from) {
    store.state.loader.loading = 0;
  }
});

router.afterEach((to, from) => {
  if (from.name) {
    nextTick(() => {
      posthog.capture("$pageview", {
        $current_url: import.meta.env.VITE_ADMIN_BASE_URL + to.fullPath,
      });
    });
  }
});

app.mount("#app");

store.$app = app;

Array.prototype.random = function _() {  // eslint-disable-line
  return this[Math.floor(Math.random() * this.length)];
};
